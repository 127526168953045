import { useMemo } from 'react';
import {
  useFilterValues,
  useGlobalFilters,
  useGetContainerStaticEvents,
  useUiEventStore,
  useUnreportedMandatoryEvents,
} from 'stores/uiEvent';
import { useFocusData } from 'stores/focus';
import { exists } from 'utils';
import { useReportStore } from 'stores/report';
import { pullLastReport } from 'stores/report/report.utils';
import { ApplicabilityReportValue } from '@jargonic/event-definition-types';
import { useContainerStore } from './container.store';
import { FilteredContainer } from './container.types';
import { countFilteredRelatedContainers } from './container.utils';

export function useFilteredContainers(containerIds: string[]): FilteredContainer[] {
  const { reports, validity, boundedness } = useReportStore(['reports', 'validity', 'boundedness']);
  const { containers, containerEventsMap, containerTemplatesMap } = useContainerStore([
    'containers',
    'containerEventsMap',
    'containerTemplatesMap',
  ]);
  const { uiEvents, visibilityBindings } = useUiEventStore(['uiEvents', 'visibilityBindings']);
  const { filters: globalFilters } = useGlobalFilters();
  const { filters } = useFilterValues();

  return useMemo(
    () =>
      containerIds
        .map((containerId) => {
          const container = containers[containerId];
          if (!exists(container)) return undefined;
          const filteredContainersAmount = countFilteredRelatedContainers({
            containers,
            container,
            filterValues: filters,
            globalFilters,
            containerEventsMap,
            containerTemplatesMap,
            uiEvents,
            reports,
            boundedness,
            validity,
            visibilityBindings,
          });

          return { container, filteredContainersAmount };
        })
        .filter((item) => !!item?.filteredContainersAmount) as FilteredContainer[],
    [
      containers,
      containerTemplatesMap,
      containerEventsMap,
      reports,
      filters,
      containerIds,
      uiEvents,
      boundedness,
      validity,
      globalFilters,
      visibilityBindings,
    ],
  );
}

export function useContainerById(containerId?: string) {
  return useContainerStore((state) => (containerId ? state.containers[containerId] : undefined));
}

export function useMandatoryEventsWithoutDefaults() {
  const getUnreportedMandatoryEvents = useUnreportedMandatoryEvents();
  const { containers } = useContainerStore(['containers']);
  const { reports } = useReportStore(['reports']);
  const getContainerStaticEvents = useGetContainerStaticEvents();
  return useMemo(() => {
    const applicableContainers = Object.values(containers).filter((container) => {
      const { applicabilityEventId } = getContainerStaticEvents(container.id);
      const eventId = applicabilityEventId;
      const applicabilityReport = pullLastReport(reports, container.id, eventId ?? '');
      return applicabilityReport?.reportedValue !== ApplicabilityReportValue.NOT_APPLICABLE;
    });
    return applicableContainers.flatMap((container) => getUnreportedMandatoryEvents(container, false));
  }, [containers, getUnreportedMandatoryEvents]);
}

export const useFocusedContainer = () => {
  const { focusedContainerId } = useFocusData();
  return useContainerById(focusedContainerId);
};
